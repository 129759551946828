<template>
  <div class="slier">
    <template v-if="isLoading">
      <div
        class="d-flex w-100 mb-4 align-items-center justify-content-center spiner-container"
        style="padding:100px 0px;"
      >
        <b-spinner
          style="width: 3rem; height: 3rem;"
          variant="warning"
        ></b-spinner>
      </div>
    </template>
    <template v-else>
      <!-- Slider main container -->
      <div class="swiper-container">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" v-for="(slide, i) in langSlides" :key="i">
            <div
              class="slide-bg"
              :style="{ backgroundImage: 'url(' + slide.image_url + ')' }"
            >
              <div
                class="video-button"
                v-b-tooltip.hover
                title="Play video"
                :data-url="slide.video_url"
                v-if="slide.has_video && slide.video_url"
              >
                <icon variant="video" color="#fff" />
              </div>
              <router-link
                :to="{ name: 'product', params: { id: slide.url } }"
              ></router-link>
            </div>
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </template>
    <b-modal id="modal-video" hide-footer hide-header centered size="lg">
      <LazyYoutube
        autoplay
        :maxWidth="videoWidth"
        aspectRatio="16:9"
        v-if="video.type == 'youtube'"
        :src="video.url"
      />
      <LazyVimeo
        autoplay
        :maxWidth="videoWidth"
        aspectRatio="16:9"
        v-if="video.type == 'vimeo'"
        :src="video.url"
      />
    </b-modal>
  </div>
</template>

<script>
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
import firebase from "firebase/app";
// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";
// import Swiper styles
import "swiper/swiper-bundle.css";

export default {
  data() {
    return {
      isLoading: false,
      slider: null,
      slides: {},
      fancybox: null,
      video: {
        url: null,
        type: null,
      },
    };
  },
  components: {
    LazyYoutube,
    LazyVimeo,
  },
  computed: {
    langSlides() {
      var slides = [];
      var lang = this.$i18n.locale;
      slides = this.slides[lang];
      return slides;
    },
    videoWidth() {
      return (window.outerWidth * 0.9).toString();
    },
  },
  methods: {
    getVideoType(url) {
      var type = "youtube";
      if (url.indexOf("youtube") > -1 || url.indexOf("youtu") > -1) {
        type = "youtube";
      }
      if (url.indexOf("vimeo") > -1) {
        type = "vimeo";
      }

      return type;
    },
    playVideo(url) {
      this.video.url = url;
      var type = this.getVideoType(url);
      this.video.type = type;
      this.$bvModal.show("modal-video");
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        loop: true,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });


      var gridButtons = document.querySelectorAll(".video-button");
      var gridButtonItems = [].slice.call(gridButtons);

      gridButtonItems.forEach((item) => {
        item.addEventListener("click", () => {
          var url = item.dataset.url;
          this.playVideo(url);
        });
      });
    },
    getSlides() {
      this.isLoading = true;
      firebase
        .firestore()
        .collection("settings")
        .doc("posters")
        .get()
        .then((doc) => {
          this.isLoading = false;
          this.slides = doc.data().slides;
          this.$nextTick(() => {
            this.initSwiper();
          });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSlides();
  },
};
</script>

<style lang="scss">
.slide-bg,
.spiner-container {
  height: 360px;
  background-color: #292a50;
  border-radius: 24px;
  width: calc(100% - 30px);
  margin: 0px auto;
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: 991px) {
    height: 280px;
  }
  @media (max-width: 768px) {
    height: 200px;
    border-radius: 12px;
  }
  @media (max-width: 500px) {
    height: 40vw;
    border-radius: 12px;
  }
  a {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 24px;
  }
}
.swiper-container {
  overflow: visible;
  margin: 0px -15px;
}
.swiper-pagination {
  position: relative;
  margin: 40px 0px 20px;
  bottom: initial;
  left: initial;
}
.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #bbbbbb;
  opacity: 1;
  margin: 0px 10px !important;
  &.swiper-pagination-bullet-active {
    background: #ffbd2c;
  }
}
.video-button {
  height: 48px;
  width: 48px;
  background: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(#000, 0.4);
  z-index: 10;
  cursor: pointer;
  &:hover {
    background: #29307a;
  }
}
</style>
