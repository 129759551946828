<template>
  <div class="container">
    <div class="home-about">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="title">
            {{ $t("about") }} Reward99
            <div class="divider"></div>
          </h3>
          <p>
            {{ $utils.getLocaleAboutHometext(about, $i18n.locale) }}
          </p>
          <b-button :to="{ name: 'about' }" variant="light" class="mt-3 mb-3">
            <span class="uppercase">
              {{ $t("learn_more") }}
            </span>
          </b-button>
        </div>
        <div class="col-12 col-md-6 text-center">
          <img
            src="@/assets/img/about_img.svg"
            style="max-width:100%;"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      isLoading: false,
      about: {},
    };
  },
  methods: {
    getAbout() {
      this.isLoading = true;
      firebase
        .firestore()
        .collection("pages")
        .doc("about")
        .get()
        .then((doc) => {
          this.isLoading = false;
          if (doc.data()) {
            this.about = doc.data();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAbout();
  },
};
</script>

<style lang="scss" scoped>
.home-about {
  background: #ffbd2c;
  border-radius: 34px;
  padding: 50px 30px;
  color: #000;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
  }
  .divider {
    border-bottom: 5px solid #ff7b22;
    width: 180px;
    margin-top: 15px;
  }
}
</style>
