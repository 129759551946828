<template>
  <div class="home">
    <div class="home__slider">
      <div class="container">
        <home-slider :key="$i18n.locale" />
      </div>
    </div>
    <div class="home__products pb-4">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="has-icon">
              <icon variant="price-tag" color="#fff" />
              {{$t('todays_hot_offers')}}
            </h1>
          </div>
          <template v-if="isLoading">
            <div class="col-6 col-sm-6 col-lg-3" v-for="n in 8" :key="n">
              <productCardSkeleton />
            </div>
          </template>
          <template v-if="!isLoading && home_products.length">
            <div
              class="col-6 col-sm-6 col-lg-3"
              v-for="p in home_products"
              :key="p.id"
            >
              <productCard :product="p" />
            </div>
            <div class="col-12 d-flex justify-content-center">
              <b-button variant="primary" :to="{name:'shop'}">{{$t('see_all_offers')}}</b-button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="home__about">
      <homeAbout />
    </div>
    <div class="home__apps">
      <homeApps />
    </div>
  </div>
</template>

<script>
import productCard from "@/components/lists/product-list-card.vue";
import homeSlider from "@/views/home-components/home-slider.vue";
import homeAbout from "@/views/home-components/home-about.vue";
import homeApps from "@/views/home-components/home-apps.vue";
import { productCardSkeleton } from "@/components/skeletons";
import firebase from "firebase/app";
export default {
  name: "Home",
  data() {
    return {
      home_products: [],
      error: null,
      isLoading: true,
    };
  },
  components: {
    productCard,
    productCardSkeleton,
    homeSlider,
    homeAbout,
    homeApps,
  },
  methods: {
    getHomeProducts() {
      this.isLoading = true;
      var db = firebase.firestore();
      db.collection("products")
        .get()
        .then((snapshot) => {
          this.home_products = [];
          snapshot.forEach((doc) => {
            this.home_products.push(doc.data());
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = true;
          this.error = error;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getHomeProducts();
  },
};
</script>
<style lang="scss" scoped>
.home {
  &__slider {
    padding: 20px 0px 0px;
    overflow: hidden;
  }
  &__about {
    margin: 60px 0px;
  }
  &__apps {
    margin: 60px 0px;
  }
  &__products {
    h1 {
      color: #fff !important;
      font-weight: 300;
      margin-bottom: 20px;
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .btn {
      height: 48px;
      width:300px;
      max-width: 100%;
      font-size: 22px;
      line-height: 1;
    }
  }
}
</style>
