<template>
  <router-link
    :to="{ name: 'product', params: { id: product.uid } }"
    class="product-card"
  >
    <div
      class="poster"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/diamond.svg') + ')',
      }"
    >
      <div
        class="poster-image"
        v-if="product.image_url"
        :style="{
          backgroundImage:
            'url(' + $utils.getLocaleProductImage(product, $i18n.locale) + ')',
        }"
      ></div>
    </div>
    <div class="name">
      {{ $utils.getLocaleProductTitle(product, $i18n.locale) }}
    </div>
    <div class="price d-flex">
      <div class="price__actual">
        ${{ product.price }}
      </div>
      <div class="price__old" v-if="product.old_price">
        ${{ product.old_price }}
      </div>
    </div>
    <div class="reward">
      <span>
        {{ $t("reward") }}
      </span>
      <div class="d-flex amount">
        <img src="@/assets/img/coin.png" alt="" />
        {{ product.coins }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["product"],
};
</script>

<style lang="scss" scoped>
.product-card {
  text-decoration: none;
  margin-bottom: 30px;
  display: block;
  .reward {
    margin-top: 10px;
    .amount {
      align-items: center;
      img {
        height: 16px;
        margin-right: 2px;
        transform: translateX(-4px);
      }
    }
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #888dff;
    }
  }
  .poster {
    padding-top: 140%;
    background: #292a50;
    margin-bottom: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30% auto;
    position: relative;
    .poster-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-position: center;
      background-size: cover;
    }
  }
  .name {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #ffffff;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .price {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffbd2c;
    &__actual {
      margin-right: 10px;
    }
    &__old {
      text-decoration: line-through;
      color: #fff;
      opacity: 0.5;
    }
  }
}
</style>
