<template>
  <div class="home-apps">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <h4 class="title">
            {{ $t("get_our_apps") }}
            <div class="divider"></div>
          </h4>
          <p>
            {{ $t("get_our_apps_paragraph") }}
          </p>
          <div class="d-flex app-links">
            <a href="#"><img src="@/assets/img/appstore.svg" alt=""/></a>
            <a href="#"><img src="@/assets/img/playmarket.svg" alt=""/></a>
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.home-apps {
  color: #fff;
  font-weight: 300;
  padding: 30px 0px;
  .app-links {
    padding: 10px 0px;
    a {
      display: block;
      margin-right: 10px;
    }
    img {
      height: 64px;
      width: auto;
      border: 2px solid rgba(#fff, 0.4);
      border-radius: 12px;
    }
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 54px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 30px;
    .divider {
      width: 180px;
      border-bottom: 5px solid #c07cd1;
      margin-top: 15px;
    }
  }
}
</style>
