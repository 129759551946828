export const productCardSkeleton = {
    functional: true,
    render() {
      return (
        <content-loader
          width="100"
          height="200"
          speed="2"
          primaryColor="#292a50"
          secondaryColor="#272867"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100" height="140" />
          <rect x="0" y="145" rx="0" ry="0" width="100" height="10" />
          <rect x="0" y="160" rx="0" ry="0" width="40" height="8" />
          <rect x="0" y="180" rx="0" ry="0" width="20" height="8" />
        </content-loader>
      );
    },
  };